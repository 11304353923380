// Colors Correct
$black: #000000;
$white: #ffffff;

// Primary
$evolveGreen: #c0d20f;
$evolveBlue: #1ab2c5;
$saldova: #093f45;
$winterPark: #12575f;
$glacier: #ddf0f2;

// Secondary
$sunValley: #ebcd12;
$portOrange: #f9a11e;
$capeCoral: #d24f45;
$smokyMountains: #41797f;
$seattle: #97c1c6;
$darkRed: #9d302b;

// Accent
$mossLanding: #d9e026;
$gatlinburg: #84be31;
$keyWest: #6bebf8;
$emeraldCoast: #14a1b2;
$amarillo: #ffe22d;
$orangeBeach: #f3b41b;
$glenRose: #f76458;
$sequim: #9d5aa3;

// Text
$headings: #093545;
$bodyText: #3e5a5e;
$overlineText: #1ab2c5;
$searchFilterText: #445a5f;

$viridianGreen: #15909E;

// Border
$inputBorder: #97c1c6;
$divider: #ebebeb;

// Gradients
$primaryGradient: linear-gradient(45deg, #43cddf, $overlineText);
$secondaryGradient: linear-gradient(90deg, $divider, #f6f6f6);

// Colors (old styling)
$suggestionHilight: #f1f1f1;
$grey-ultra-light: #fcfbf9;
$grey-dot: rgba(43, 41, 46, 0.2);
$grey-dot-active: #1bb2c5;
$grey-very-light: #e4e4e4;
$grey-light: #cfcfcf;
$grey: #7a7a7a;
$grey-darker: #979797;
$grey-dark: #999999;
$grey-transparent: rgba(235, 235, 235, 0.5);
$gainsboro: #d8d8d8;
$red: #d24f45;
$eminence: #764485;
$blue: #29b2c4;
$pattens-blue: #f0f8f9;
$summer-sky: #2dbbd1;
$dark-cerulean: #854592;
$blue-extra-dark: #093f46;
$blue-dark: #0e3f44;
$blue-placeholder: #3e5a5e;
$tax-break: #496569;
$turquoise-blue: #64dbea;
$med-turquoise: #37b9cd;
$light-turquoise: #1bb2c5;
$blumine: #2f6268;
$eastern-blue: #0d8695;
$casal: #3e5a5e;
$yellow: #c0d02e;
$light-yellow: #f6f9db;
$orange: #e49734;
$dark-orange: #f9a01f;
$dark-orange-opaque: rgba(249, 160, 31, 0.4);
$salomie: #ffcd83;
$bittersweet: #f76458;
$light-green: #fef1de;
$dark-green: #c0d20f;
$dark-green-opaque: rgba(192, 210, 15, 0.4);
$mindaro: #dee96d;
$apple: #5cb147;
$elf-green: #219e49;
$cyprus: #0a3f46;
$pine-green: #066c78;
$light-cyan: #ddf4f7;
$light-sea-green: #1ab2c5;
$light-sea-green-opaque: rgba(26, 178, 197, 0.4);
$paradiso: #4e848b;
$input-border: $grey-light;

// Linear Gradient
$linearGradient: linear-gradient(#f9fdfd, #ddf0f2);
$linearGradientFull: linear-gradient(#ffffff00, #ddf0f2);
$linearGradientFullReverse: linear-gradient(#ddf0f2, #ffffff00);
