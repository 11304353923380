// Screen Sizes
$lgDesktop: 1440px;
$desktop: 1280px;
$mdDesktop: 1205px;
$smDesktop: 1024px;
$lgTablet: 1000px;
$tablet: 900px;
$smTablet: 768px;
$mobile: 600px;
$smallMobile: 375px;
$searchPageMobile: 865px;
$searchPageHamburger: 1255px;

// Typography
$font-primary: 'Roboto Slab', sans-serif;
$font-bold: 600;
$font-bolder: 700;
