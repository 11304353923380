* {
  box-sizing: border-box;

  &:root {
    font-size: 15px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  box-sizing: border-box;
  background-color: #ddd;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
}

button {
  color: $black;
}

a {
  color: $blue;
  text-decoration: none;
}

input {
  background: white;
}
