body {
  font-family: $font-primary;
  font-weight: 400;

  letter-spacing: 0.33px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-primary;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.125rem;
  letter-spacing: -0.3px;
  line-height: 3.125rem;
}

h2 {
  font-size: 2.825rem;
  line-height: 40px;
}

h3 {
  font-size: 2.5rem;
  line-height: 36px;
}

h4 {
  font-size: 2rem;
  line-height: 26px;
}

h5 {
  font-family: $font-primary;
  font-size: 1rem;
  font-weight: 600;
  line-height: 21px;
}

.body {
  font-size: 1rem;
  line-height: 24px;
}
